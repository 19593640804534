/* Generated by Font Squirrel (https://www.fontsquirrel.com) on December 20, 2016 */

@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans/opensans-bold.eot');
    src: url('../fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans/opensans-bold.woff2') format('woff2'), url('../fonts/opensans/opensans-bold.woff') format('woff'), url('../fonts/opensans/opensans-bold.ttf') format('truetype'), url('../fonts/opensans/opensans-bold.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans/opensans-semibold.eot');
    src: url('../fonts/opensans/opensans-semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans/opensans-semibold.woff2') format('woff2'), url('../fonts/opensans/opensans-semibold.woff') format('woff'), url('../fonts/opensans/opensans-semibold.ttf') format('truetype'), url('../fonts/opensans/opensans-semibold.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans/opensans-light.eot');
    src: url('../fonts/opensans/opensans-light.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans/opensans-light.woff2') format('woff2'), url('../fonts/opensans/opensans-light.woff') format('woff'), url('../fonts/opensans/opensans-light.ttf') format('truetype'), url('../fonts/opensans/opensans-light.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans/opensans-regular.eot');
    src: url('../fonts/opensans/opensans-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans/opensans-regular.woff2') format('woff2'), url('../fonts/opensans/opensans-regular.woff') format('woff'), url('../fonts/opensans/opensans-regular.ttf') format('truetype'), url('../fonts/opensans/opensans-regular.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
